* {
    box-sizing: border-box;
}

body {
    background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    color: #555;
    line-height: 2em;
    padding: 0;
    margin: 0;
    width: 100%;
    min-width: 320px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}

#page {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.block {
    background: #eee;
    padding: 2em 2em;
    font-size: 2em;
    box-shadow:
  0 7.8px 10px rgba(0, 0, 0, 0.05),
  0 62px 80px rgba(0, 0, 0, 0.1);
}

.date-container {
    padding-top: 0.5em;
    font-size: 0.8em;
}
